import Link from "next/link";
import { useRootApp } from "../../../../state/hooks/useRootAppState/useRootAppState";
import { useClient } from "../../../../state/hooks/useClientState";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";


interface CoreProps {
  name: string;
  mobile?: boolean;
}

const ActiveStoreBarCore = (props: CoreProps) => {

  return (
    <>
      <div className="flex flex-shrink-0 px-[20px] py-[12px] justify-between items-center self-stretch md:max-w-[1100px] md:w-full md:mx-auto gap-[10px]">
        <div className="flex gap-[8px] items-center">
          <div className="w-[24px] h-[24px] relative">
            <svg>
              <use href="/sprites/sprites.svg#infoIcon" />
            </svg>
          </div>
          <div className="flex flex-col items-start">
            <p className="font-font-family-body text-[12px] italic font-normal leading-none">
              Your store
            </p>
            
            <Link className="font-font-family-body text-[16px] font-semibold leading-none"
            href={props.mobile ? "/mobile/menu" : "/menu"}
            >
              {props.name}
            </Link>
          </div>
        </div>
        <div>
          <Link
            href={props.mobile ? "/mobile/store-locator" : "/store-locator"}
            className={`flex p-[10px] rounded-[4px] self-stretch justify-center items-center w-full h-[32px]  ${props.mobile ? "bg-accents-primary-button" : "bg-accents-primary-accent"}`}>
            Change
          </Link>
        </div>
      </div>
    </>
  );
};

interface ActiveStoreBarProps {
  hideSericeSelect?: boolean;
}

export const AppActiveStoreBar = (props: ActiveStoreBarProps) => {
  const rootApp = useRootApp();
  const activeStore = rootApp.activeStore.state;
  const cdata = useClient();
  const router = useRouter();

  const isHomePage = router.pathname === "/[host]/mobile"
  const attrs = cdata.siteJson.siteAttributes || {}
  const pickupText = attrs.pickupText;
  const deliveryText = attrs.deliveryText;


  return (
    <>
      {activeStore.store && (
        <>
          <div className={"bg-accents-primary-accent text-white w-full z-[50] sup-active-bar-app pt-6 " + (isHomePage ? "sup-animate" : "")}  >
            <ActiveStoreBarCore name={activeStore.store.name} mobile={true} />
          </div>


          {!props.hideSericeSelect && (<>
            <div className=" py-3 px-4 flex justify-between items-center w-full sup-service-type">
              <select
                value={activeStore.serviceType}
                onChange={(ev) => {
                  rootApp.activeStore.setServiceType(ev.target.value as any);
                }}
                className="border border-gray-500 rounded-md py-3 px-4 text-lg w-full">
                <option value={"DELIVERY"} >Ordering for {deliveryText || "delivery"}</option>
                <option value={"TAKE_AWAY"}>Ordering for {pickupText || "collection"}</option>
              </select>
            </div>
          </>)}

        </>
      )}
    </>
  );
};


export const NonAppActiveStoreBar = () => {
  const rootApp = useRootApp();
  const activeStore = rootApp.activeStore.state;

  return (
    <>
      {activeStore.store && (
        <>
          <div className="bg-accents-secondary-accent w-full sticky top-[80px] z-[50] sup-active-bar">
            <ActiveStoreBarCore name={activeStore.store.name} />
          </div>
        </>
      )}
    </>
  );
};

