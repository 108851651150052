

export interface PropsType {
    name: string
    className?: string
}

const SpriteIcon = (props: PropsType) => {
    return (
        <div className={`${props.className || ""}`}>
            <svg>
                <use href={`/sprites/sprites.svg#${props.name}`} />
            </svg>
        </div>
    )
}



export default SpriteIcon