
import React from 'react';
import NavSection from "../../Nav/NavSection";
import FooterSection from "../../Footer/FooterSection";
import { NonAppActiveStoreBar } from '../../Nav/ActiveStoreBar';
import { WithGoogleMaps } from '../../../../pages/_app';
import { useClient } from '../../../../state/hooks/useClientState';


const WithNonMobileAppLayout = (props: { children: React.ReactNode }) => {
  const ClientData = useClient();
  const siteAttributes = ClientData?.siteJson?.siteAttributes || {};
  const googleMapsKey = siteAttributes.googleMapsApiKey || "";

  return (<>
        <WithGoogleMaps apiKey={googleMapsKey}>
          <NavSection />
            <div className="pt-[80px] min-h-[calc(85vh-160px)] sup-main-content sup-main-nonmobile">
              <NonAppActiveStoreBar />
              {props.children}
            </div>
          <FooterSection />
      </WithGoogleMaps>
  </>)
}


export default WithNonMobileAppLayout;
