import { useRouter } from "next/router";
import { useClient } from "../../../state/hooks/useClientState";
import { useRootApp } from "../../../state/hooks/useRootAppState/useRootAppState";
import config from "../../../config/app";
import { useModal } from "../../../components/modal/modal";
import DebugPanel from "./DebugPanel";

const Footer = (props: any) => {
  const router = useRouter();
  const rootApp = useRootApp();
  const model = useModal()

  const cdata = useClient();


  const siteJson = cdata.siteJson



  const footerContent =
    siteJson.footerContent || 
    siteJson.siteAttributes?.copyrightText || "";

  const feedbackEmail = `mailto:${siteJson.feedbackEmail ||
    "feedback@serveup.software"
    }`;

  const socials = siteJson.socialLinks || {};
  const appLinks = siteJson.appLinks || {};
  const footerLinks = siteJson.footerLinks || [];


  const navigateTo = (link: any) => {
    const { firstTimeSignUp, firstTimeSignUpFlag } = props;
    if (firstTimeSignUpFlag) {
      firstTimeSignUp({ firstTimeSignup: false });
    }

    if (link.newTab) {
      window.open(link.link, "_blank");
      return
    }

    // firstTimeSignUp({ firstTimeSignup: false });
    router.push(link);
  };

  return (
    <div className="sup-footer mt-10">
      <div className="flex p-[20px] flex-col items-start gap-[20px] self-stretch md:max-w-[1100px] md:w-full md:mx-auto sup-footer-content">

        <div className="flex p-[20px] flex-col items-start gap-[20px] self-stretch md:max-w-[1100px] md:w-full md:mx-auto">

          {footerLinks.map((link) => (
            <div
              key={link.title}
              className="flex justify-between items-start self-stretch sup-footer-link cursor-pointer"
              onClick={() => navigateTo(link.link)}
            >
              <p className="text-[16px] font-semibold font-font-family-body">
                {link.title}
              </p>
              <div className="flex w-[16px] h-[16px] py-[1px] justify-center items-center my-auto relative">
                <svg>
                  <use href="/sprites/sprites.svg#whiteArrow" />
                </svg>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center gap-[8px] w-fit mx-auto sup-apps">
          {Object.keys(appLinks).map((appName) => {
            const appLink = (appLinks as any)[appName];
            return ((
              <>
                {appLink && (<>
                  <div
                    key={appName}
                    onClick={() => {
                      window.open(appLink, "_blank");
                    }}
                    className="w-[119.665px] h-[40px] relative sup-app-item">
                    <svg>
                      <use href={`/sprites/sprites.svg#${appName}`} />
                    </svg>
                  </div>
                </>)}
              </>
            ))
          })}

        </div>

        <div className="flex gap-[20px] items-center w-fit mx-auto sup-social">
          {Object.keys(socials).map((socialName) => (
            <div key={socialName} className="w-[20px] h-[20px] relative sup-social-item">
              <a href={(socials as any)[socialName] as string}
                target="_blank"
              >
                <svg>
                  <use href={`/sprites/sprites.svg#${socialName}`} />
                </svg>
              </a>
            </div>
          ))}

          {rootApp.debug && (
            <>
              <button
                type="button"
                onClick={() => {
                  model.show(DebugPanel, {

                  })

                }}
              >
                DEBUG
              </button>
            </>
          )}
        </div>

        <p className="text-center text-[12px] font-font-family-body w-fit mx-auto sup-copyright">
          {getDateFixedCopyrightText(footerContent)}
        </p>
      </div>
    </div>
  );
};

const getDateFixedCopyrightText = (text: string) => {
  const yearRegex = /\d{4}/;
  const match = yearRegex.exec(text);
  if (match) {
    const currentYear = new Date().getFullYear();
    if (match[0] !== String(currentYear)) {
      return text.replace(yearRegex, String(currentYear));
    }
  }
  return text;
};

export default Footer;
