

const DebugPanel = (props: any) => {

    return (<>
        <div className={`bg-white mx-auto max-w-md rounded-md flex flex-col sup-card gap-4 p-4 ${props.className || ""}`}>
            <div className="flex gap-2">
                <h2>Debug Panel</h2>
            </div>
        </div>
    </>)
}

export default DebugPanel;