
import React from 'react';
import SpriteIcon from '../../../order/checkout-pro/sub/widgets/SpriteIcon';
import Link from 'next/link';
import { AppActiveStoreBar } from '../../Nav/ActiveStoreBar';
import { getMenuPageURL, useRootApp } from '../../../../state/hooks/useRootAppState/useRootAppState';
import { useRouter } from 'next/router';
import { useClient } from '../../../../state/hooks/useClientState';
import { WithGoogleMaps } from '../../../../pages/_app';


interface PropsType {
  children: React.ReactNode;
  hideFooter?: boolean;
}



const WithMobileLayout = (props: PropsType) => {
  const ClientData = useClient();
  const siteAttributes = ClientData?.siteJson?.siteAttributes || {};
  const googleMapsKey = siteAttributes.googleMapsMobileAppApiKey || siteAttributes.googleMapsApiKey || "";

  return (<>
    <WithGoogleMaps apiKey={googleMapsKey}>
      <div className="min-h-[80vh] sup-main-content sup-main-mapp">
        <AppActiveStoreBar />

        <div className="w-full h-full pb-20">
          {props.children}
        </div>

        {!props.hideFooter && (<>
          <MobileAppFooter />
        </>)}
      </div>
    </WithGoogleMaps>
  </>)
}


const MobileAppFooter = () => {
  const rootApp = useRootApp();
  const router = useRouter();
  const cdata = useClient();

  const isClassicMode = !!cdata.siteJson?.siteAttributes?.mobileAppMenuClassicMode;
  const cartcount = Object.keys(rootApp.cart.state.items).length;
  const isStoreSelected = !!rootApp.activeStore.state.store;
  const isLoggedIn = rootApp.auth.state.loggedIn
  const isHomePage = router.pathname === "/[host]/mobile";
  const isCartEmpty = cartcount === 0;

  console.log("isHomePage", isHomePage);



  return (<>

    <div className={`fixed bottom-0 left-0 right-0 border-t bg-accents-primary-accent text-white rounded-xl sup-mapp-footer ${isHomePage ? "sup-home-page" : "sup-not-home-page"}`}>
      <div className={`grid px-4 py-2 sup-mapp-nav ${ isCartEmpty ? "grid-cols-3" : "grid-cols-4" }`}>


        {isClassicMode && (<>
          <div className='flex items-center justify-center sup-mapp-nav-button'>
            <Link
              href="/mobile"
              className="flex flex-col items-center justify-center"
            >
              <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" height="100%" width="100%" className='w-6 h-6 mb-1'>
                <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
              </svg>
              <span className="text-xs">Home</span>
            </Link>
          </div>
        </>)}


        {(isStoreSelected || !isClassicMode) && (<>
          <div className='flex items-center justify-center sup-mapp-nav-button'>
            <button
              className="flex flex-col items-center justify-center"
              onClick={() => {



                if (isStoreSelected) {
                  const murl = getMenuPageURL(rootApp.activeStore.state.store)
                  router.push(`/mobile/${murl}`)
                } else {
                  router.push("/mobile/store-locator")
                }
              }}
            >
              <SpriteIcon name="menuIcon" className="w-6 h-6 mb-1" />
              <span className="text-xs">Menu</span>
            </button>
          </div>
        </>)}

        {((isClassicMode && !isStoreSelected) || (!isClassicMode)) && (<>
          <div className='flex items-center justify-center sup-mapp-nav-button'>
            <Link
              href="/mobile/store-locator"
              className="flex flex-col items-center justify-center"
            >
              <SpriteIcon name="storeIcon" className="w-6 h-6 mb-1" />
              <span className="text-xs">Stores</span>
            </Link>
          </div>
        </>)}

        <div className='flex items-center justify-center sup-mapp-nav-button'>
          <Link
            href={isLoggedIn ? "/mobile/profile" : "/mobile/login"}
            className="flex flex-col items-center justify-center "
          >
            <SpriteIcon name="profileIcon" className="w-6 h-6 mb-1" />
            <span className="text-xs">Profile</span>
          </Link>
        </div>



        {!isCartEmpty && (<>
          <div className='flex items-center justify-center sup-mapp-nav-button fade-in'>
            <Link
              href="/mobile/cart"
              className="flex items-center justify-center"
            >

              <div className="inline-flex items-center justify-center rounded-lg bg-accents-secondary-accent px-3 py-2 gap-1">
                <SpriteIcon name="cart" className="w-5 h-5 mb-1 relative" />
                {!isCartEmpty && (
                  <span className="font-font-family-body text-lg font-normal leading-normal">{cartcount}</span>
                )}
              </div>

            </Link>
          </div>
        </>)}





      </div>
    </div>
  </>)
}


export default WithMobileLayout;
